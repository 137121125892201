<template>
  <div class="application-page">
    <div class="subscribe-row">
      已订购系统数量：<span v-if="list" class="num">{{ list.length || 0 }}</span>
    </div>

    <div class="product-list">
      <div v-for="(item, index) in list" :key="index" class="product-item" @click="gotoapp(item)">
        <div class="product-info">
          <div class="summary">
            <div class="left">
              <div class="app-img">
                <img v-if="item.name === 'CHB'" alt="" src="../../assets/logo-chb.png">
                <img v-if="item.name === 'OMS'" alt="" src="../../assets/logo-oms.png">
                <img v-if="item.name === 'CRM'" alt="" src="../../assets/logo-crm.png">
              </div>
            </div>
            <div class="right">
              <div class="name">
                <div>{{ item.name }}</div>
              </div>
              <!-- <div class="app-key">
                介绍：{{ item.introduce }}
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getApplicationList
} from '@/api/application';

export default {
  data () {
    return {
      list: [],
    };
  },
  watch: {
  },
  mounted () {
    this.fetch();
  },
  methods: {
    fetch () {
      getApplicationList().then(res => {
        this.list = res;
      });
    },
    gotoapp (item) {
      const domain = window.location.protocol + "//" + window.location.host;
      const appKey = item.appKey;
      window.open(`${domain}/oauth/authorize?response_type=code&client_id=${appKey}&view=ACCOUNT`);
    },
  }
};
</script>

<style lang='scss' scoped>
@import '@/style/imports/variables.scss';
.application-page {
  .subscribe-row {
    padding: 28px 32px;
    background: #fff;
    color: #666;
    .num {
      font-size: 20px;
      color: $color-primary;
      font-weight: 600;
    }
    .add-btn {
      float: right;
    }
  }
  .product-list {
    margin-top: 25px;
    margin-right: -25px;
    display: flex;
    flex-wrap: wrap;
    .product-item {
      margin: 0 25px 25px 0;
      min-width: 280px;
      .product-info {
        position: relative;
      }
      .pro-img {
        width: 100%;
      }
      .summary {
        padding: 20px 24px 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .right {
          margin-left: 12px;
        }
        .name {
          font-size: 17px;
          color: #222;
          font-weight: 500;
        }
        .app-key {
          color: #888;
          margin-top: 4px;
          word-break: keep-all;
        }
        .app-img {
          background-color: #fff;
          display: flex;
          align-items: center;
          width: 60px;
          height: 60px;
          border-radius: 12px;
          padding: 0 4px;
          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      .reset-btn {
        margin-top: 15px;
        text-align: right;
      }
    }
  }
}
</style>
