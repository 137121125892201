import fetch from './req';

export function getApplicationList (query) {
  return fetch({
    url: '/application/all',
    method: 'get',
    params: query
  });
}
export function getAppManageList (query) {
  return fetch({
    url: '/application/list',
    method: 'get',
    params: query
  });
}

export function resetApplicationSecret (params) {
  return fetch({
    url: '/application/resetSecret',
    method: 'post',
    data: params
  });
}

export function createApplication (params) {
  return fetch({
    url: '/application/create',
    method: 'post',
    data: params
  });
}

export function updateApplication (params) {
  return fetch({
    url: '/application/update',
    method: 'post',
    data: params
  });
}

// 启用停用应用
export function switchApplication (params) {
  return fetch({
    url: '/application/restore',
    method: 'post',
    data: params
  });
}

// 获得应用数据可见范围树结构
export function getAppPermission (params) {
  return fetch({
    url: '/permission/app/visible',
    method: 'get',
    params,
  });
}

// 设置应用数据可见范围树结构
export function setAppPermission (params) {
  return fetch({
    url: '/application/setVisibleRange',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

// 应用元数据列表
export function getMetadataList (params = {}) {
  return fetch({
    url: '/metadata/app/list',
    method: 'post',
    data: params
  });
}

// 安装应用
export function installApp (params = {}) {
  return fetch({
    url: '/metadata/install/app',
    method: 'post',
    data: params
  });
}